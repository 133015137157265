import { keyframes } from '@emotion/react'

export const SLIDE_UP = keyframes`
    0% {
        transform: translateY(85%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
`

export const SLIDE_DOWN = keyframes`
    0% {
        transform: translateY(-85%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
`

export const SLIDE_RIGHT = keyframes`
    0% {
        transform: translateX(-85%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
`

export const SLIDE_LEFT = keyframes`
    0% {
        transform: translateX(85%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
`
export const FADE_IN = keyframes`
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`
