import * as React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import Row from '../components/row'
import Title from '../components/title'
import PricingCard from '../components/pricing-card'
import Text from '../components/text'
import Column from '../components/column'
import Animation from '../components/animation'
import { COLORS } from '../assets/theme'
import {
  GROUP_PRICING_DATA,
  PRICING_CARD_DATA,
} from '../constants/pricing-data'
import { determinePosition } from '../helper'
import { FADE_IN } from '../assets/animations'
import GroupPricingCard from '../components/group-pricing-card'
import Border from '../components/border'

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Jiu-Jitsu</title>
        <meta
          title='Jiu-Jitsu'
          name='description'
          content='One on One Private Brazilian Jiu-Jitsu lessons with one of the best experts and practitioners of Brazilian Jiu-Jitsu'
        ></meta>
      </Helmet>
      <Column height='100%'>
        <Column marginTop='5'>
          <Title
            as='h2'
            color={COLORS.fontWhite}
            fontSize='2.2em'
            lineHeight='1.15'
            textAlign='center'
          >
            1-on-1
          </Title>
          <Border
            alignSelf='center'
            height='2px'
            width='50px'
            marginBottom='10px'
            borderRadius='2px'
          />
          <Row
            flexWrap='wrap'
            justifyContent='center'
            alignItems='flex-end'
            gridGap='4'
          >
            {PRICING_CARD_DATA.map((data, index) => (
              <PricingCard
                key={`pricing-card-${index}`}
                {...data}
                position={determinePosition(index, PRICING_CARD_DATA.length)}
              />
            ))}
          </Row>
        </Column>

        <Column marginTop='5'>
          <Title
            as='h2'
            color={COLORS.fontWhite}
            fontSize='2.2em'
            lineHeight='1.15'
            textAlign='center'
          >
            Group Lessons
          </Title>
          <Border
            alignSelf='center'
            height='2px'
            width='155px'
            marginBottom='10px'
            borderRadius='2px'
          />
          <Row
            flexWrap='wrap'
            justifyContent='center'
            alignItems='flex-end'
            gridGap='4'
          >
            {GROUP_PRICING_DATA.map((data, index) => (
              <GroupPricingCard
                key={`pricing-card-${index}`}
                {...data}
                position={determinePosition(index, GROUP_PRICING_DATA.length)}
              />
            ))}
          </Row>
        </Column>

        <Row
          justifyContent='center'
          mt='2'
          paddingX='1'
          paddingBottom='1'
          color={COLORS.darkerGray}
          flexWrap='wrap'
        >
          <Animation animation={FADE_IN} duration={1.75} bezier='ease-in-out'>
            <Text textAlign='center'>
              *All payments upfront before scheduling any private lesson
            </Text>
          </Animation>
        </Row>
      </Column>
    </Layout>
  )
}

export default IndexPage
