export const determinePosition = (pos: number, total: number): string => {
  if (pos === total - 1) {
    return 'last'
  }

  switch (pos) {
    case 0:
      return 'first'
    default:
      return 'other'
  }
}
