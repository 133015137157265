import React from 'react'
import { COLORS } from '../../assets/theme'
import Column from '../column'
import Panel from '../panel'
import Text from '../text'

interface Savings {
  savingsPer: string
  totalSavings: string
}

export interface PricingCardProps {
  lessons: string
  price: string
  savings?: Savings
  main?: boolean
  position?: string
}

const GroupPricingCard = ({
  lessons,
  price,
  savings,
  main = false,
  position,
}: PricingCardProps) => (
  <Panel
    main={main}
    padding='4'
    borderRadius={6}
    height={main ? 400 : 350}
    minHeight={main ? 400 : 350}
    width={300}
    minWidth={300}
    position={position}
  >
    <Column alignItems='center' width='100%'>
      <Text fontSize='5' lineHeight='1.15'>
        {lessons}
      </Text>
      <Text fontSize='3' color={COLORS.darkGray}>
        {`60 `}
        <Text fontSize='1'>min session</Text>
      </Text>
      {savings ? (
        <>
          <Text fontSize='6' lineHeight='1' paddingTop='4'>
            ${price}
          </Text>
          <Text fontSize='3' lineHeight='1'>
            ${`${savings.savingsPer} `}
            <Text fontSize='1' color={COLORS.secondary}>
              / per student
            </Text>
          </Text>
          <Text fontSize='5' lineHeight='1' paddingTop='4'>
            Savings
          </Text>
          <Text fontSize='6'>${savings.totalSavings}</Text>
        </>
      ) : (
        <>
          <Text fontSize='6' lineHeight='1' paddingTop='4'>
            ${price}
          </Text>
          <Text fontSize='2' color={COLORS.secondary}>
            / per class
          </Text>
        </>
      )}
    </Column>
  </Panel>
)

export default GroupPricingCard
