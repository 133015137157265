import { type Keyframes, css } from '@emotion/react'
import styled from '@emotion/styled'

interface AnimationProps {
  animation: Keyframes
  duration: number
  bezier: string
  delay?: number
}

const Animation = styled.div<AnimationProps>`
  animation: ${({ animation, duration, bezier }) => css`
    ${animation} ${duration}s ${bezier}
  `};
  ${({ delay }) => (delay ? `animation-delay: ${delay}s` : '')};
`

export default Animation
