import { type PricingCardProps } from './../components/pricing-card/index'

const ONE_LESSON: PricingCardProps = {
  lessons: '1 Lesson',
  price: '100',
}

const FIVE_LESSONS: PricingCardProps = {
  lessons: '5 Lessons',
  price: '450',
  savings: {
    savingsPer: '90',
    totalSavings: '50',
  },
}

const TEN_LESSONS: PricingCardProps = {
  lessons: '10 Lessons',
  price: '800',
  savings: {
    savingsPer: '80',
    totalSavings: '200',
  },
  main: true,
}

const TWENTY_LESSONS: PricingCardProps = {
  lessons: '20 Lessons',
  price: '900',
  savings: {
    savingsPer: '40',
    totalSavings: '400',
  },
}

export const PRICING_CARD_DATA: Array<PricingCardProps> = [
  ONE_LESSON,
  TEN_LESSONS,
  FIVE_LESSONS,
]

const GROUP_LESSONS_THREE: PricingCardProps = {
  lessons: '3+ Group lesson',
  price: '270+',
  savings: {
    savingsPer: '90',
    totalSavings: '45',
  },
}

const GROUP_LESSONS_FIVE: PricingCardProps = {
  lessons: '5+ Group lesson',
  price: '400+',
  savings: {
    savingsPer: '80',
    totalSavings: '100',
  },
}

const GROUP_LESSONS_EIGHT: PricingCardProps = {
  lessons: '8+ Group lesson',
  price: '560+',
  savings: {
    savingsPer: '70',
    totalSavings: '240',
  },
  main: true,
}

export const GROUP_PRICING_DATA = [
  GROUP_LESSONS_THREE,
  GROUP_LESSONS_EIGHT,
  GROUP_LESSONS_FIVE,
]
