import { css, Keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import {
  layout,
  LayoutProps,
  flexGrow,
  FlexGrowProps,
  flexShrink,
  FlexShrinkProps,
  space,
  SpaceProps,
  borderRadius,
  BorderRadiusProps,
} from 'styled-system'
import { SLIDE_LEFT, SLIDE_RIGHT, SLIDE_UP } from '../../assets/animations'
import { COLORS } from '../../assets/theme'

interface ComponentProps {
  main?: boolean
}

const mainCard = `
    border-color: ${COLORS.secondary};
    position: relative;
    overflow: hidden;
    &::after {
        content: "Best";
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: end;
        top: 0;
        right: 0;
        width: 100px;
        height: 100px;
        color: ${COLORS.secondary};
        background-color: ${COLORS.black};
        transform: translate(50px, -50px) rotate(45deg);
        box-shadow: 1px 2px 10px -3px #c1cad6;
    }
    &::before {
        content: "";
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        height: 44px;
        width: 44px;
        background-color: ${COLORS.background};
        transform: translate(22px, -22px) rotate(45deg);
    }
`

const defaultCard = `
  border-color: ${COLORS.darkGray};
`

const determineSlide = (pos: string): Keyframes => {
  switch (pos) {
    case 'first':
      return SLIDE_RIGHT
    case 'last':
      return SLIDE_LEFT
    case 'other':
    default:
      return SLIDE_UP
  }
}

interface PanelProps {
  position?: string
}
type PanelType = PanelProps &
  LayoutProps &
  FlexGrowProps &
  FlexShrinkProps &
  SpaceProps &
  BorderRadiusProps &
  ComponentProps

export const Panel = styled('div')<PanelType>`
  border: solid 2px;
  display: flex;
  color: ${COLORS.fontWhite};
  background-color: ${COLORS.background};
  box-shadow: 0px 2px 10px 4px rgba(170, 171, 184, 0.4);
  ${({ position }) =>
    position
      ? css`
          animation: ${determineSlide(position)} 1s ease-in-out;
        `
      : ''}
  ${({ main }) => (main ? mainCard : defaultCard)};
  ${layout};
  ${space};
  ${borderRadius};
  ${flexGrow};
  ${flexShrink};
`

export default Panel
